import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Uploadcare’s Transfer Impact Assessment',
  description: '',
  header: 'Uploadcare’s Transfer Impact Assessment'
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Uploadcare safeguards the personal data our customers entrust us to process when we must transfer that data to a third country — whether for the purposes of providing to you our services, support, analysis, security, or sub-processing.`}</p>
    <p>{`Uploadcare’s `}<a parentName="p" {...{
        "href": "/about/dpa/"
      }}>{`Data Protection Addendum`}</a>{` now incorporates the the Standard Contractual Clauses (`}<a parentName="p" {...{
        "href": "https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?uri=CELEX:32021D0914&locale=en"
      }}>{`Model Two`}</a>{`). In response to the heightened requirements created by the Schrems II decision, these new SCCs require a data importer (such as us) to provide specific information about data transfers it undertakes, and requires importers to conduct a transfer impact assessment to evaluate risks involved with the transfer of personal data to countries outside the EEA. The SCCs also require a data importer to take into account any supplemental technical and organizational security measures and additional assessments may be required to mitigate risks before transferring any personal data across borders.`}</p>
    <p>{`In Annex I of our SCCs you can find information about:`}</p>
    <ul>
      <li parentName="ul">{`Categories of data subjects whose personal data is transferred`}</li>
      <li parentName="ul">{`Categories of personal data transferred`}</li>
      <li parentName="ul">{`Sensitive data transferred`}</li>
      <li parentName="ul">{`The frequency of the transfer `}</li>
      <li parentName="ul">{`Nature of the processing`}</li>
      <li parentName="ul">{`Purpose(s) of the data transfer and further processing`}</li>
      <li parentName="ul">{`The period for which the personal data will be retained`}</li>
      <li parentName="ul">{`For transfers to (sub-) processors, it also specifies subject matter, nature and duration of the processing`}</li>
    </ul>
    <p>{`Annex II of our SCCs provides you with `}<a parentName="p" {...{
        "href": "/about/security-whitepaper/"
      }}>{`technical and organisational measures`}</a>{` to ensure the security of the data. We are currently in the process of implementing additional supplementary measures such as regionalized storage, and will continue to watch for additional guidance from our Data Protection Authorities and from the European Data Protection Board (EDPB).`}</p>
    <p>{`Annex III of our SCCs gives you a `}<a parentName="p" {...{
        "href": "/about/sub-processors/"
      }}>{`list of sub-processors`}</a>{`.`}</p>
    <p>{`You can also find our transparency report `}<a parentName="p" {...{
        "href": "/about/law-enforcement/"
      }}>{`here`}</a>{`.`}</p>
    {
      /* Additionally, each Uploadcare sub-processor has a law enforcement request policy in place and will notify Uploadcare, where permitted by law, before disclosing information in response to a request. */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      